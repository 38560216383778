<template>
  <app-layout-main class="p-4">
    <div
      class="
        container
        px-1
        py-8
        m-auto
        mx-auto
        md:px-8
        max-w-xl max-h-90
        space-y-20
        bg-contain bg-app-logo bg-no-repeat bg-center
      "
    >
      <app-logo-payje class="-mt-20 h-10" />

      <app-card class=" -top-6 m-8">
        <h3 class="mb-4 font-semibold md:text-xl text-center">
          {{ $t("auth.register.password_changed") }}
        </h3>
        <p class="mb-4 text-center text-gray-500 text-sm">
          {{ $t("auth.register.password_changed_description") }}
        </p>

        <app-button :loading="loading" @onClick="backLogin">
          {{ $t("auth.login.return_signin") }}
        </app-button>
      </app-card>
    </div>
    <app-alert />
  </app-layout-main>
</template>
<script>
export default {
  methods: {
    backLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
